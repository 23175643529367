import style from "./header.module.scss";
import menu from "../../assets/icons/menu.svg";
import fullLogo from "../../assets/icons/fullLogo.svg";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const path = useLocation().pathname;

  const navigate = useNavigate();

  const toggleExpand = () => setIsExpand((v) => !v);

  return (
    <>
      <div className={style.main}>
        <img
          onClick={() => navigate("/")}
          className={style.logo}
          src={fullLogo}
          alt=""
        />
        <div className={style.mobile}>
          <button onClick={toggleExpand}>
            <img src={menu} alt="" />
          </button>
        </div>
        <div className={style.desktop}>
          <a className={path === "/" ? style.activeItem : style.item} href="/">
            Startseite
          </a>
          <a
            className={
              path === "/results-&-schedules" ? style.activeItem : style.item
            }
            href="/results-&-schedules"
          >
            Spielplan und Tabelle
          </a>
          {/* <a
            className={path === "/news" ? style.activeItem : style.item}
            href="/news"
          >
            News
          </a> */}
        </div>
      </div>

      <span
        onClick={() => setIsExpand(false)}
        className={style.dropBack}
        style={
          isExpand ? { opacity: "25%" } : { opacity: "0", display: "none" }
        }
      ></span>
      <div
        className={style.expandable}
        style={isExpand ? {} : { transform: "translatex(100%)" }}
      >
        <span className={style.expandableItemsContainer}>
          <a className={path === "/" ? style.activeItem : style.item} href="/">
            Startseite
          </a>
          <a
            className={
              path === "/results-&-schedules" ? style.activeItem : style.item
            }
            href="/results-&-schedules"
          >
            Spielplan und Tabelle
          </a>
          {/* <a
            className={path === "/news" ? style.activeItem : style.item}
            href="/news"
          >
            News
          </a> */}
        </span>
      </div>
    </>
  );
};
export default Header;
