import React from "react";
import "./App.scss";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Router from "./router";
import { useLocation } from "react-router-dom";

function App() {
  const path: string = useLocation().pathname.toLowerCase();
  const hideNavbarIn: any[] = [/\/admin\/*/];
  const hideFooterIn: any[] = [/\/admin\/*/];

  return (
    <div className="main">
      {!hideNavbarIn.some((e) => e?.test(path)) ? <Header /> : null}
      <div className="grow">
        <Router />
      </div>
      {!hideFooterIn.some((e) => e?.test(path)) ? <Footer /> : null}
    </div>
  );
}

export default App;
