// import Layout from "./Components/Layout"
import { Routes, Route, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import ANews from "./pages/news/a-news";

const Router = () => {
  const Home = lazy(() => import("./pages/home"));
  const ResultsSchedule = lazy(() => import("./pages/results-&-schedule"));
  const AdminRouter = lazy(() => import("./pages/admin/router"));

  const NotFound = () => {
    return (
      <>
        <div className="mt-[120px] text-center title-1 ">Not found (404)</div>
      </>
    );
  };

  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-full mt-24">
          Loading ...
        </div>
      }
    >
      <Routes>
        <Route path="/results-&-schedules" element={<ResultsSchedule />} />
        <Route path="/news/:id" element={<ANews />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
