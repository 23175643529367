import style from "./Anews.module.scss";
import arr from "../../../assets/icons/arrowdown2.svg";
import calender from "../../../assets/icons/calendar.svg";
import clock from "../../../assets/icons/clock.svg";
import news from "../../../assets/home/sampleNews.png";
import { useNavigate, useParams } from "react-router-dom";
const ANews = () => {
  const id = useParams().id;
  const navigate = useNavigate();

  return (
    <>
      <div className={style.main}>
        <button onClick={() => navigate(-1)} className={style.backBtn}>
          <img src={arr} alt="" />
        </button>
        <div className={style.detail}>
          <div className={style.imgSection}>
            <img src={news} alt="" />
            <div>
              <span>
                <img src={calender} alt="calender" />
                <span>January 9, 2022</span>
              </span>
              <span>
                <img src={clock} alt="calender" />
                <span>15 min.</span>
              </span>
            </div>
          </div>
          <div className={style.paragraph}>
            <h3>News title news title news title</h3>
            <p>
              Et quod fugit aut natus eaque 33 fugiat sapiente eum libero ipsam
              At reiciendis modi qui voluptates sequi. Hic doloribus illo qui
              error fugiat id facilis numquam est rerum repellat sit repudiandae
              tempora. Cum similique nihil ab sint dolores et. Et quod fugit aut
              natus eaque 33 fugiat sapiente eum libero ipsam At reiciendis modi
              qui voluptates sequi. Hic doloribus illo qui error fugiat id
              facilis numquam est rerum repellat sit repudiandae tempora. Cum
              similique nihil ab sint dolores et.
            </p>
            <img src={news} alt="news" />
            <p>
              Et quod fugit aut natus eaque 33 fugiat sapiente eum libero ipsam
              At reiciendis modi qui voluptates sequi. Hic doloribus illo qui
              error fugiat id facilis numquam est rerum repellat sit repudiandae
              tempora. Cum similique nihil ab sint dolores et. Et quod fugit aut
              natus eaque 33 fugiat sapiente eum libero ipsam At reiciendis modi
              qui voluptates sequi. Hic doloribus illo qui error fugiat id
              facilis numquam est rerum repellat sit repudiandae tempora. Cum
              similique nihil ab sint dolores et.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ANews;
